//News listing
$("#add-grid").click(function() {
   $("#NewsListing").addClass("grid-view");
   $("#NewsListing").removeClass("list-view");
   $(this).addClass("active");
   $("#add-list").removeClass("active");
});

$("#add-list").click(function() {
   $("#NewsListing").addClass("list-view");
   $("#NewsListing").removeClass("grid-view");
   $(this).addClass("active");
   $("#add-grid").removeClass("active");
});

//Doc list
$("#add-grid").click(function() {
   $(".auto-list").addClass("grid-view-list");
   $(".auto-list").removeClass("list-view-list");
   $(this).addClass("active");
   $("#add-list").removeClass("active");
});

$("#add-list").click(function() {
   $(".auto-list").addClass("list-view-list");
   $(".auto-list").removeClass("grid-view-list");
   $(this).addClass("active");
   $("#add-grid").removeClass("active");
});