//quick links
jQuery(function($) {

	// jQuery 1.9 > Toggle Event dep 1.8

	$('#slideClick').click(function() {
		var it = $(this).data('it') || 1;
		
		switch ( it ) {
			case 1 :
				$(this).parent().animate({left:'0px'}, {queue:false, duration: 500});
				break;
			case 2 :
				$(this).parent().animate({left:'-250px'}, {queue: false, duration: 500});
				break;
		}
		it++;
		if(it > 2) it = 1;
		$(this).data('it', it);
		$(this).toggleClass( "open" );
	})
	
	//Set slideOut height
	$("#slideOut").css({'height':($("#slideContent").height()+'px')});
});


$(document).ready(function() {
//RESPONSIVE CODE
    function checkWidth() {
    var windowSize = $(window).width();
    if (windowSize < 750) {
      // If mobile: Insert news and diary dates after content on homepage
      $("#slideOut").insertBefore("header");
      $('#slideContent').css('display','none');
    }
    else {
      // Otherwise reposition them before the content on homepage
      $(".Content2Column").insertAfter(".ColumnRight");
      $('#slideContent').css('display','block');

    }
    }
    checkWidth();  
    $(window).bind('resize orientationchange', function() {
    checkWidth();
    });


});


<!-- Mobile -->

$(document).ready(function() {
	$(".flip").click(function() {
		$(this).toggleClass( "open" );
		$("#slideContent").slideToggle("slow");
	});
});
